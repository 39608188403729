@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼オリジナルブランド
  -------------------------------------*/
.p-originalbrand {
  .link_bnr {
    display: block;
    width: 40%;
    margin: 0 auto;
    position: relative;
    @include m.pc_s {
      width: 75%;
      margin: 1rem auto 3rem auto;
    }
    @include m.sp {
      margin: 0 auto 3rem auto;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 1em;
      background-color: rgba(0, 60, 80, 0.4);
    }
    span {
      display: block;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include m.tab_s {
        @include m.fz(20);
      }
      @include m.sp_s {
        @include m.fz(18);
      }
    }
    &:hover {
      &:before {
        background-color: rgba(0, 60, 80, 0.6);
      }
    }
    &_item {
      margin-bottom: 1rem;
    }
  }
}
