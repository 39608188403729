@use "global/mixin" as m;
@use "global/variable" as v;

.c-post {
  &_nav {
    width: 100%;
    margin: 0 auto 5rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include m.sp {
      margin: 4rem auto 3rem auto;
    }
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .radio {
      position: relative;
      display: inline-block;
      padding: 0 5rem 0 2.5rem;
      cursor: pointer;
      @include m.tab_s {
        padding: 0 3rem 0 2rem;
        margin-bottom: 1rem;
      }
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 18px;
        height: 18px;
        margin-top: -8px;
        border-radius: 50%;
        border: 1px solid v.$main;
        background: #fff;
        @include m.sp_s {
          width: 15px;
          height: 15px;
          margin-top: -7px;
        }
      }
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 3px;
        width: 12px;
        height: 12px;
        margin-top: -5px;
        border-radius: 50%;
        background: v.$main;
        opacity: 0;
        transition: opacity 0.2s;
        @include m.sp_s {
          width: 9px;
          height: 9px;
          margin-top: -4px;
        }
      }
      &.active,
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }

    input[type="radio"]:checked + label::after {
      opacity: 1;
    }

    input[type="radio"]:focus + label::before {
      box-shadow: 0 0 7px v.$main;
      border: 1px solid v.$main;
    }
  }
  &_item {
    dl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 1em 0;
      @include m.pc_s {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    dt,
    dd {
      width: 100%;
      @include m.fz(14);
      line-height: 1.7;
    }
    dt {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    &__category {
      text-align: center;
      display: inline-block;
      @include m.fz(10);
      line-height: 1.8;
      color: v.$main;
      margin-left: 1rem;
      padding: 0 1rem;
      background: v.$white;
      border: 1px solid v.$main;
    }
    &__head {
      padding-bottom: 1em;
      margin-bottom: 3em;
      border-bottom: 1px solid v.$main;
    }
    &__contents {
      margin-bottom: 2em;
    }
    &__inner {
      width: 95%;
      margin: 0 auto 5em auto;
    }
    &__img {
      margin-bottom: 1em;
    }
    .c-btn {
      margin: 0 auto;
    }
    &.__fx {
      display: flex;
      justify-content: space-between;
      padding: 0;
      @include m.sp {
        flex-direction: column-reverse;
      }
    }
    &__col {
      width: 60%;
      padding: 2rem;
      @include m.pc_s {
        width: 80%;
        padding: 1rem;
      }
      @include m.sp {
        width: 100%;
        min-height: auto;
        padding: 1rem;
      }
    }
    &__rt_img {
      width: 75%;
      margin: 0;
      position: relative;
      @include m.sp {
        width: 100%;
      }
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 0;
        padding-top: 60%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
.__member {
  .c-post {
    &_label {
      display: inline-block;
      @include m.fz(17);
      padding: 0.5rem 5rem;
      margin-bottom: 2rem;
      color: v.$white;
      @include m.sp_m {
        @include m.fz(15);
        padding: 0.3rem 2rem;
      }
      &.yakuin {
        background: v.$orange;
      }
      &.yasai {
        background: v.$yellow_green;
      }
      &.kajitsu {
        background: v.$pink;
      }
      &.haiso {
        background: v.$blue;
      }
      &.somujimu {
        background: v.$yellow;
      }
    }
    &_item {
      margin: 1rem;
      margin-bottom: 5rem;
      @include m.b_shadow;
      position: relative;
      @include m.pc {
        width: calc((100% / 2) - 3rem);
      }
      @include m.sp {
        width: 100%;
        margin-bottom: 3rem;
      }

      dl {
        padding: 0;
        justify-content: flex-start;
        dt,
        dd {
          margin-bottom: 0.5rem;
          @include m.sp_m {
            @include m.fz(13);
          }
        }
        dt {
          width: 25%;
          @include m.tab_s {
            width: 100%;
          }
        }
        dd {
          width: 75%;
          @include m.tab_s {
            width: 100%;
          }
        }
      }
      &__head {
        margin-bottom: 1rem;
      }
      .department,
      .name {
        @include m.fz(16);
        line-height: 1.5;
      }
      .text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        @include m.sp_m {
          @include m.fz(13);
          line-height: 1.5;
        }
      }
      &__col {
        min-height: 250px;

        @include m.sp {
          min-height: auto;
        }
      }
    }
  }
}
// wp
.wp-block-image {
  margin: 1em 0;
}
