@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼会社概要
  -------------------------------------*/
.p-about {
  &.__philosophy {
    #value1,
    #value2,
    #value3,
    #value4 {
      .l-section_inner {
        @include m.pc_s {
          padding: 5rem 0 0 0;
        }
      }
    }
    #value5 {
      .l-section_inner {
        @include m.pc_s {
          padding: 5rem 0 10rem 0;
        }
        @include m.sp {
          padding: 5rem 0;
        }
      }
    }
  }
  &.__kenkoukeiei {
    #certification {
      .l-section_fx__col_inner {
        @include m.tab_s {
          order: 3;
        }
      }
      .img_rt {
        @include m.tab_s {
          order: 2;
          margin-bottom: 1rem;
        }
      }
    }
    #activities {
      background: v.$gray;
      .l-section {
        &_inner {
          max-width: 1350px;
        }
        &_fx {
          justify-content: center;
          align-items: flex-start;
          margin: 0 -1rem;
          @include m.tab_s {
            margin: 0 -0.5rem;
          }
          figure {
            width: calc((100% / 4) - 2rem);
            margin: 1rem 1rem 5rem 1rem;
            @include m.tab_s {
              width: calc((100% / 3) - 1rem);
              margin: 0.5rem 0.5rem 2rem 0.5rem;
            }
            @include m.sp {
              width: 46%;
            }
            @include m.sp_m {
              width: 80%;
              img {
                width: 80%;
                margin: 0 auto;
              }
            }
          }
          figcaption {
            @include m.fz(26);
            font-weight: bold;
            line-height: 1.4;
            text-align: center;
            padding-top: 1rem;
            @include m.pc1300 {
              @include m.fz(20);
            }
            @include m.pc_s {
              @include m.fz(18);
            }
            p {
              @include m.fz(14);
              line-height: 1.4;
              padding-top: 1rem;
            }
          }
        }
      }
    }
    #system {
      .l-section {
        &_inner {
          padding-bottom: 3rem;
        }
      }
      .c-title {
        @include m.tab_s {
          margin-bottom: 4rem;
        }
      }
      .system_img {
        max-width: 797px;
        margin: 0 auto;
      }
    }
    #thanks {
      .l-section {
        &_inner {
          padding-bottom: 3rem;
        }
        &_fx {
          @include m.tab_s {
            flex-direction: column-reverse;
          }
        }
      }
    }
    #reunion {
      z-index: 2;
      padding-bottom: calc(24 / 1400 * 100vw);
      @include m.tab_s {
        padding-bottom: 0;
      }
      .l-section {
        &_fx__img {
          @include m.pc1500 {
            display: flex;
            justify-content: flex-end;
          }
          .img_btm {
            width: calc(380 / 1000 * 100%);
            position: absolute;
            left: calc(-600 / 1400 * 100vw);
            bottom: calc(-320 / 1400 * 100vw);
            z-index: -1;
            @include m.pc1500 {
              position: relative;
              width: 40%;
              left: auto;
              bottom: auto;
              margin: 5rem 0 0 -5rem;
            }
          }
          .img {
            @include m.pc1500 {
              width: 70%;
              margin: 0 auto 0 0;
              z-index: 2;
            }
          }
        }
      }
      .c-box_pages {
        @include m.pc_sOver {
          padding: 2rem 4rem;
          margin-left: -4rem;
        }
      }
    }
  }
  &.__sdgs {
    .l-section {
      @include m.sp {
        padding: 5rem 0;
      }
      &_logo {
        @include m.sp {
          width: 70%;
          margin: 0 auto 3rem auto;
        }
      }
      &_fx {
        // justify-content: flex-start;
      }
      &_icon_list {
        .btn {
          width: calc((100% / 6) - 20px);
          margin: 10px;
          @include m.sp {
            width: calc((100% / 3) - 10px);
            margin: 5px;
          }
        }
      }
    }
    .c-modal {
      &__img {
        @include m.pc {
          width: 30%;
        }
        @include m.sp {
          width: 40%;
          margin: 1rem 0;
        }
      }
      &__text {
        @include m.pc {
          width: 65%;
        }
      }
    }
  }
  &.__character {
    .l-section {
      &_illust {
        width: 80%;
        margin: 0 auto 10rem;
        @include m.sp {
          margin: 0 auto 5rem;
        }
        .illust {
          width: calc((100% / 4) - 20px);
          margin: 10px;
          @include m.sp {
            width: calc((100% / 2) - 20px);
          }
        }
      }
    }
  }
}
