@use "global/mixin" as m;
@use "global/variable" as v;

/*--------------------------------------
 form
---------------------------------------*/
.p-contact {
  .l-section_inner {
    max-width: 850px;
    width: 90%;
  }
  &__form {
    margin-bottom: 5rem;
    text-align: left;
    dt {
      width: 100%;
      margin-bottom: 1rem;
      @include m.sp {
        line-height: 1.5;
        margin-bottom: 0.5em;
      }
    }
    dd {
      width: 100%;
      margin-bottom: 2em;
      @include m.sp {
        margin-bottom: 1em;
      }
    }
    /* placeholder */
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: #ccc;
    }
    .inputs {
      width: 100%;
      height: 60px;
      @include m.sp {
        height: 50px;
      }
    }
    .must {
      @include m.fz(14);
      color: v.$green;
      margin-left: 0.5em;
      @include m.sp {
        display: block;
        margin-left: 0;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      width: 100%;
      outline: none;
      background-clip: padding-box;
      padding: 0.8em;
      font-size: 100%;
      @include m.b_frame;
      background: #fff;
      &:focus {
        box-shadow: 0 0 7px v.$green;
        border: 1px solid v.$green;
      }
    }
    .textarea {
      width: 100%;
      height: 150px;
      max-height: 250px;
      min-height: 250px;
      padding: 1em 0.8em 0.8em 0.8em;
      box-sizing: border-box;
    }
    /* radio */
    input[type="radio"] {
      display: none;
    }
    .radio {
      position: relative;
      display: inline-block;
      padding: 0 1em 0 1.7em;
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        border-radius: 100%;
        transition: all 0.2s;
      }

      &::before {
        left: 0;
        width: 18px;
        height: 18px;
        margin-top: -10px;
        background: v.$white;
        border: 1px solid v.$main;
      }
      &::after {
        opacity: 0;
        left: 3px;
        width: 12px;
        height: 12px;
        margin-top: -7px;
        background: v.$green;
      }
    }
    input[type="radio"]:checked + label::after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    .wpcf7-list-item-label {
      cursor: pointer;
      display: flex;
      &::before {
        background: v.$white;
        border: 1px solid v.$main;
        border-radius: 100%;
        content: "";
        height: 1.2em;
        margin-bottom: auto;
        margin-right: 0.5em;
        margin-top: auto;
        transition: background-color 0.5s;
        width: 1.2em;
      }
    }
    input[type="radio"]:checked + .wpcf7-list-item-label::before {
      background-color: v.$green;
      box-shadow: inset 0 0 0 3px #fff;
    }
  }
  &__btn {
    width: 70%;
    margin: 3rem auto 0 auto;
    @include m.pc_s {
      width: 90%;
    }
    @include m.sp_579 {
      flex-direction: column;
    }
    a,
    .c-btn {
      &::after {
        display: none;
      }
      &:nth-of-type(2) {
        @include m.pc {
          margin-left: 2em;
        }
        @include m.sp {
          margin-top: 1.5em;
        }
      }
    }
    li {
      @include m.sp_579 {
        margin: 0 auto 1.5rem auto;
      }
    }
  }
  &__thanks {
    p {
      text-align: center;
      @include m.sp {
        text-align: left;
      }
    }
  }
  .c-btn {
    &:hover {
      cursor: pointer;
    }
  }
  /*-------------------------------
お問い合わせ　エラー画面　contactus error
-------------------------------*/
  .error {
    display: block;
    font-size: 1rem !important;
    font-weight: bold;
    line-height: 2em;
    text-align: left;
    margin: 0 auto 0 0;
  }
  /*-------------------------------
WP Form
-------------------------------*/

  &__form {
    &.__confirm {
      width: 80%;
      margin: 0 auto 3rem auto;
      @include m.pc_s {
        width: 100%;
      }
    }
  }
}
