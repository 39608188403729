@use "global/mixin" as m;
@use "global/variable" as v;

.p-news {
  .c-post_item {
    dl {
      border-bottom: 1px solid v.$main;
      padding: 2rem 0;
      @include m.sp {
        padding: 1rem 0;
      }
    }
    dt,
    dd {
      @include m.fz(14);
      line-height: 1.7;
    }
  }
  .pagination {
    width: 95%;
    margin: 3em auto 0 auto;
    display: flex;
    justify-content: center;

    &_inner,
    .wp-pagenavi {
      display: flex;
      text-align: center;
    }
    &__detail {
      width: 95%;
      margin: 3rem auto 0 auto;
      display: flex;
      justify-content: center;
      li {
        &:nth-of-type(2) {
          margin: 0 5rem;
          @include m.sp {
            margin: 0 3rem;
          }
        }
      }
      .prev,
      .next {
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          width: 10px;
          height: 10px;
          margin-top: -3px;
          transform: translate(-50%, -50%);
          border-right: 2px solid v.$main;
          border-bottom: 2px solid v.$main;
        }
        &:hover {
          &::after {
            border-right: 2px solid v.$green;
            border-bottom: 2px solid v.$green;
          }
        }
      }
      .prev {
        &:after {
          left: -25%;
          transform: rotate(135deg);
        }
      }
      .next {
        &:after {
          right: -20%;
          transform: rotate(-45deg);
        }
      }
      a {
        &:hover {
          color: v.$green;
          text-decoration: underline;
        }
      }
    }
    span,
    a,
    .page {
      display: block;
      width: 35px;
      height: 35px;
      line-height: 33px;
      border-radius: 50%;
      margin-right: 1em;
      background: v.$white;
      position: relative;
      @include m.sp {
        width: 30px;
        height: 30px;
        line-height: 28px;
        margin-right: 0.7em;
      }
    }
    a {
      &:hover {
        color: #fff;
        background: v.$main;
      }
    }
    .previouspostslink,
    .nextpostslink {
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30%;
        margin-top: -4px;
        width: 7px;
        height: 7px;
        border-right: 2px solid v.$main;
        border-bottom: 2px solid v.$main;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .previouspostslink {
      &:before {
        transform: rotate(135deg);
      }
    }
    .nextpostslink {
      &:before {
        transform: rotate(-45deg);
      }
    }

    .current {
      color: #fff;
      background: v.$main;
    }
    &__detail {
    }
  }
}
