@use "global/mixin" as m;
@use "global/variable" as v;

.c-title {
  @include m.fz(20);
  font-weight: bold;
  line-height: 1.5;
  @include m.pc_s {
    @include m.fz(18);
  }
  @include m.sp {
    @include m.fz(15);
  }
  span {
    display: block;
    @include m.fz(60);
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: 1.5rem;
    @include m.pc_s {
      @include m.fz(45);
      margin-bottom: 1rem;
    }
    @include m.sp {
      @include m.fz(35);
    }
  }
  &--medium {
    @include m.fz(20);
    line-height: 1.2;
    font-weight: bold;
    @include m.sp {
      @include m.fz(15);
      line-height: 1.4;
    }
    span {
      display: block;
      @include m.fz(45);
      font-weight: 900;
      margin-bottom: 2rem;
      @include m.pc_s {
        @include m.fz(35);
      }
      @include m.sp {
        @include m.fz(30);
      }
      @include m.sp_m {
        @include m.fz(25);
      }
      @include m.sp_s {
        @include m.fz(23);
      }
    }
  }
  &--small {
    span {
      @include m.fz(40);
      line-height: 1;
      @include m.sp {
        @include m.fz(30);
      }
    }
    @include m.sp {
      @include m.fz(15);
    }
  }
  &--pages {
    .en {
      @include m.fz(20);
      color: v.$white;
      background: v.$main;
      @include m.tab {
        @include m.fz(18);
      }
      @include m.sp {
        @include m.fz(16);
      }
      @include m.sp_l {
        @include m.fz(15);
      }
    }
    .jp {
      @include m.fz(30);
      font-weight: bold;
      line-height: 1.7;
      background: v.$white;
      color: v.$main;
      @include m.tab {
        @include m.fz(23);
      }
      @include m.sp {
        @include m.fz(20);
      }
      @include m.sp_l {
        @include m.fz(18);
      }
    }
  }
}
.c-text {
  &--small {
    @include m.fz(14);
    line-height: 1.5;
    @include m.sp {
      @include m.fz(12);
    }
  }
  &--medium16 {
    @include m.fz(16);
    line-height: 1.7;
    @include m.sp {
      @include m.fz(15);
    }
    @include m.sp_s {
      @include m.fz(14);
    }
  }
  &--medium18 {
    @include m.fz(18);
    line-height: 1.7;
    @include m.sp {
      @include m.fz(16);
    }
    @include m.sp_s {
      @include m.fz(15);
    }
  }
  &--medium20 {
    @include m.fz(20);
    line-height: 1.5;
    @include m.sp {
      @include m.fz(18);
    }
    @include m.sp_s {
      @include m.fz(16);
    }
  }
  &--medium {
    @include m.fz(24);
    line-height: 1.7;
    @include m.sp {
      @include m.fz(22);
    }
    @include m.sp_m {
      @include m.fz(20);
    }
    @include m.sp_s {
      @include m.fz(17);
    }
    span {
      @include m.fz(30);
      @include m.sp {
        @include m.fz(25);
      }
      @include m.sp_m {
        @include m.fz(23);
      }
      @include m.sp_s {
        @include m.fz(18);
      }
    }
  }
  &--large {
    @include m.fz(36);
    line-height: 1.4;
    @include m.sp {
      @include m.fz(25);
    }
    @include m.sp_s {
      @include m.fz(23);
    }
  }
  &--notes {
    @include m.fz(10);
  }
}
