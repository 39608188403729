@use "global/mixin" as m;
@use "global/variable" as v;

.u-font {
  &__bold {
    font-weight: bold;
  }
  &__black {
    font-weight: 900;
  }
}
