@use "global/mixin" as m;
@use "global/variable" as v;

.c-list {
  &_item {
    &.__fx {
      display: flex;
      flex-wrap: wrap;
      dt,
      dd {
        width: 100%;
      }
      dt {
        padding: 15px 0 5px 0;
        display: flex;
        align-items: center;
        @include m.pc {
          width: 18%;
          padding: 15px 0;
          border-bottom: 1px solid v.$main;
        }
      }
      dd {
        padding: 0 0 15px 0;
        @include m.pc {
          width: 82%;
          padding: 15px 0;
        }
      }
    }
    dt {
      color: v.$green;
      padding: 15px 0 5px 0;
    }
    dd {
      border-bottom: 1px solid v.$main;
      padding-bottom: 15px;

      @include m.sp {
        line-height: 1.5;
      }
    }
    li {
      text-indent: -1em;
      margin-left: 1em;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: v.$green;
        vertical-align: middle;
        margin: -3px 5px 0 0;
      }
    }

    ol {
      li {
        text-indent: -1.5em;
        margin-left: 1em;
        margin-bottom: 1em;
        &::before {
          display: none;
        }
      }
    }
  }
}
