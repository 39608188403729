@use "global/mixin" as m;
@use "global/variable" as v;

//--- animation ---//
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
//fade
@keyframes fade_up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade_lt {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.js_anime {
  opacity: 0;
  &.__fadeup {
    &.scroll {
      animation: fade_up 1s forwards;
    }
    &.delay05s {
      animation-delay: 0.5s;
    }
    &.delay1s {
      animation-delay: 1s;
    }
  }
  &.__fade {
    &.scroll {
      animation: fade 1s forwards;
    }
    &.delay05s {
      animation-delay: 0.5s;
    }
    &.delay1s {
      animation-delay: 1s;
      @include m.sp {
        animation-delay: initial;
      }
    }
  }
}
// zoomout
@keyframes zoomout {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
