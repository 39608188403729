@use "global/mixin" as m;
@use "global/variable" as v;
/*-------------------------------
  Footer
-------------------------------*/
.l-footer {
  color: v.$white;
  background: v.$main;
  padding: 10rem 0 5rem;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 80px;
    height: 26px;
    background: url(../../assets/img/common/footer_icon.svg) right / contain no-repeat;
    position: absolute;
    top: -25px;
    right: 15%;
  }
  @include m.sp {
    padding: 4rem 0;
  }
  &_inner {
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
  }
  &_item {
    margin-bottom: 8rem;
    @include m.sp {
      margin-bottom: 4em;
    }
  }
  &_info {
    width: 20%;
    @include m.tab_s {
      width: 100%;
    }

    .logo {
      @include m.sp {
        width: 35%;
      }
      @include m.sp_579 {
        width: 50%;
      }
    }
    li {
      margin-bottom: 1rem;
      &:nth-of-type(1) {
        margin: 4rem 0 1.5rem 0;
        @include m.sp {
          margin: 1rem 0;
        }
      }
    }
  }
  &_nav {
    width: 70%;
    @include m.pc1200 {
      width: 75%;
    }
    @include m.tab_s {
      width: 100%;
    }

    &__item {
      width: 30%;
      border-top: 1px solid v.$white;
      @include m.tab_s {
        width: 46%;
      }
      li {
        padding: 2rem 0 4rem;
        border-bottom: 1px solid v.$white;

        @include m.tab_s {
          padding: 1rem 0;
        }
        &:nth-last-of-type(1) {
          border: none;
        }
      }
    }
    &__sub {
      li {
        padding: 0 0 1rem 0;
        border: none;
      }
      .nav_s {
        @include m.fz(12);
        font-weight: 400;
        position: relative;
        @include m.sp_s {
          @include m.fz(11);
        }
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: v.$yellow_green;
          vertical-align: middle;
          margin: 0 1.5rem 0 0;
          @include m.sp {
            margin: 0 0.5rem 0 0;
          }
        }
      }
    }
    &__link {
      @include m.tab {
        width: 46%;
      }
      @include m.sp {
        width: 80%;
        margin: 3rem auto 0 auto;
      }
      a {
        display: block;
        line-height: 1.5;
        padding: 1rem;
        margin-bottom: 2rem;
        border: 1px solid v.$white;
        text-align: center;
        @include m.pc {
          width: 220px;
        }
        @include m.tab_s {
          width: 100%;
        }
      }
    }
  }
  .copy {
    display: block;
    text-align: center;
    @include m.sp {
      @include m.fz(10);
    }
  }
}

/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 3;
  right: 1em;
  bottom: 2em;
  @include m.sp {
    right: 0.5em;
  }
  a {
    display: block;
    color: v.$main;
    font-weight: bold;
    background: v.$gray;
    border: 2px solid v.$main;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
    @include m.sp {
      width: 50px;
      height: 50px;
      line-height: 50px;
      @include m.fz(12);
      letter-spacing: normal;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-right: 2px solid v.$main;
      border-top: 2px solid v.$main;
      transform: rotate(-45deg);
      position: absolute;
      top: 30%;

      @include m.sp {
        width: 7px;
        height: 7px;
      }
    }
  }
}
