@use "sass:math";
@use "global/variable" as v;

@mixin pcOver($width: 1501px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin pcOver1201($width: 1201px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin pc1500($width: 1500px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin pc1400($width: 1400px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin pc1300($width: 1300px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin pc1200($width: 1200px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin pc_sOver($width: 1151px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin pc_s($width: 1150px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin tabOver($width: 901px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}
@mixin tab_s($width: 900px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin sp_l($width: 640px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin sp_579($width: 579px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin sp_mOver($width: 481px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin sp_m($width: 480px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin sp_s($width: 380px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// font size
@mixin fz($size, $base: 15) {
  font-size: $size + px;
  font-size: calc(#{$size} / #{$base}) + rem;
}

@function vw($size, $viewport: 1480) {
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

// 上記のvwを利用したフォントサイズの指定
@mixin fz_vw($font_size: 10) {
  font-size: $font_size * 1px; //vw非対応の場合の保険

  @include pc {
    font-size: vw($font_size, 1500); //フォントをvw指定
  }

  @include pcOver {
    font-size: $font_size * 1px;
  }
}

// line-height
@mixin lineHeight($line-height) {
  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
  }
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em); //文字上の余白を消す
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em); //文字下の余白を消す
  }
}
// boxshadow
@mixin b_shadow {
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.18);
}

// border
@mixin b_frame {
  border: 1px solid v.$main;
  border-radius: 0.5em;
}
