@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼採用情報​ //Recruit
  -------------------------------------*/
.p-recruit {
  #data {
    .l-section {
      &_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row-reverse;
        @include m.tab_s {
          flex-direction: column;
        }
        .data_text {
          width: 40%;
          margin-top: -5rem;
          @include m.tab_s {
            width: 100%;
            margin: 0 0 5rem 0;
          }
          @include m.sp {
            margin: 0 0 2rem 0;
          }
        }
        .swiper03 {
          width: 100%;
          flex: 1;
          margin-left: calc(50% - 50vw);
          // margin-right: 4%;
          overflow: hidden;
          position: relative;
          padding-bottom: 5rem;
          @include m.sp {
            overflow: visible;
            padding-bottom: 4rem;
          }
          .swiper-slide {
            transform: scale(0.8);
            transition: 0.7s;
            height: auto;
            @include m.sp_m {
              // padding: 0 1%;
            }
          }
          .swiper-slide img {
            height: auto;
            width: 100%;
          }
          .swiper-slide-active {
            opacity: 1;
            transform: scale(1);
            z-index: 1;
          }
          .swiper-pagination {
            bottom: 0;
            &-bullet-active {
              background: v.$yellow_green;
            }
          }
          // .swiper-button-next,
          // .swiper-button-prev {
          //   color: v.$yellow_green;
          //   &::after {
          //     @include m.sp_579 {
          //       font-size: 3rem;
          //     }
          //   }
          // }
          // .swiper-button-prev {
          //   left: var(--swiper-navigation-sides-offset, -30px);
          //   @include m.sp_579 {
          //     left: 0;
          //   }
          // }
          // .swiper-button-next {
          //   right: var(--swiper-navigation-sides-offset, -30px);
          //   @include m.sp_579 {
          //     right: 0;
          //   }
          // }
        }
      }
      &_data {
        // max-width: 300px;
        height: 100%;
        text-align: center;
        padding: 1rem;
        border: 1px solid v.$main;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include m.sp {
          padding: 1rem 0.5rem;
        }
        @include m.sp_m {
          padding: 1rem 0.5rem;
          // min-height: 400px;
        }
        &__title {
          @include m.fz(30);
          font-weight: bold;
          line-height: 1.3;
          margin-bottom: 1.5rem;
          @include m.sp {
            @include m.fz(25);
            margin-bottom: 0.5rem;
          }
          .title {
            display: block;
            @include m.fz(28);
            margin-bottom: 1rem;
            @include m.sp {
              @include m.fz(20);
              margin-bottom: 0.5rem;
            }
            &_s {
              @include m.fz(25);
              @include m.sp {
                @include m.fz(16);
              }
            }
            &_b {
              display: block;
              @include m.fz(16);
              @include m.sp {
                @include m.fz(15);
              }
            }
          }
        }
        &__img {
          margin-bottom: 2rem;
          @include m.sp {
            margin-bottom: 0.5rem;
          }
          img {
            @include m.sp {
              width: 80px;
              height: 80px;
            }
          }
        }
        .text {
          @include m.fz(15);
          line-height: 1.5;
          @include m.sp {
            @include m.fz(14);
          }
        }
      }
    }
  }
  #interview {
    .c-post_item {
      width: 70%;
      margin: 0 auto 5rem auto;
      align-items: center;
      @include m.b_shadow;
      transform: 0.3s;
      @include m.pc_s {
        width: 85%;
      }
      @include m.sp {
        margin-bottom: 3rem;
      }
      &:nth-child(odd) {
        @include m.pc {
          margin-left: 5%;
        }
      }
      &:nth-child(even) {
        @include m.pc {
          margin-right: 5%;
        }
      }
      &__head {
        margin-bottom: 1rem;
        // &_inner {
        //   display: flex;
        //   .department {
        //     margin-right: 1rem;
        //   }
        // }
      }
      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        p {
          opacity: 0.8;
        }
      }
    }
  }
  #job {
  }
  #qa {
    .text {
      @include m.sp_m {
        text-align: left;
      }
    }
  }
  /*------------------------------------
   ▼社員紹介
  -------------------------------------*/
  &.__member {
    .l-section {
      &.__reverse {
        .l-section {
          &_fx__img {
            @include m.tabOver {
              margin-left: calc(50% - 50vw);
              margin-right: 5%;
            }
          }
        }
      }
    }
    .title {
      margin-bottom: 2rem;
      span {
        display: block;
      }
    }
    #job_desc {
      .c-list_item {
        dd {
          p {
            margin-bottom: 2rem;
          }
        }
      }
    }
    .c-post {
      &_item {
        @include m.pc {
          width: calc((100% / 3) - 3rem);
        }
        @include m.tab_s {
          width: 80%;
          margin-bottom: 2rem;
          flex-direction: row;
        }
        @include m.sp {
          width: 100%;
        }
        &__col {
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include m.sp {
            min-height: auto;
            text-align: center;
          }
        }
      }
    }
  }
  /*------------------------------------
   ▼募集要項
  -------------------------------------*/
  &.__job {
    #job_desc {
      .l-section {
        &_item {
          @include m.pcOver1201 {
            max-width: 870px;
          }
        }
      }
    }

    .c-hero_item_img {
      img {
        @include m.sp_l {
          object-position: right;
        }
      }
    }
    #flow {
      .l-section_flow {
        max-width: 1000px;
        margin: 0 auto 5rem auto;
        &__item {
          // height: 120px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          // border: 1px solid v.$main;
          border-bottom: 1px solid v.$main;
          padding: 2.5rem;
          @include m.sp {
            padding: 1rem;
            flex-direction: column;
          }
          // &::after {
          //   content: "";
          //   display: inline-block;
          //   width: 0;
          //   height: 0;
          //   border-style: solid;
          //   border-right: 20px solid transparent;
          //   border-left: 20px solid transparent;
          //   border-top: 20px solid v.$main;
          //   border-bottom: 0;
          //   position: absolute;
          //   bottom: calc(-700 / 1400 * 100%);
          //   left: calc(310 / 1400 * 100%);
          //   transform: translate(-50%, -50%);
          //   @include m.pc_s {
          //     bottom: calc(-380 / 1150 * 100%);
          //     left: calc(300 / 1150 * 100%);
          //   }
          //   @include m.sp {
          //     border-right: 15px solid transparent;
          //     border-left: 15px solid transparent;
          //     border-top: 15px solid v.$main;
          //     bottom: -2.5rem;
          //   }
          //   @include m.sp_m {
          //     bottom: -16%;
          //     right: 40%;
          //   }
          // }
          // &:nth-last-of-type(1) {
          //   &::after {
          //     display: none;
          //   }
          // }
          &:nth-of-type(3) {
            .l-section_flow__icon {
              width: 19%;
              img {
                margin-left: 1rem;
                @include m.pc_s {
                  margin: 0;
                }
              }
            }
          }
        }
        &__title {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include m.pc_s {
            width: 100%;
            flex-direction: column;
          }
          @include m.sp {
            justify-content: center;
          }
          .title {
            padding: 0 2rem;
          }

          p {
            line-height: 1.4;
            text-align: center;
          }
        }
        &__icon {
          width: 15%;
          @include m.pc_s {
            padding: 0.5rem 0;
          }
          @include m.sp {
            width: 10%;
          }
        }
        &__text {
          width: 55%;
          @include m.pc_s {
            width: 100%;
          }
          @include m.sp {
            text-align: center;
          }
        }
      }
    }

    #job {
      .l-section_fx {
        justify-content: center;
      }
    }
  }
}
