@use "global/mixin" as m;
@use "global/variable" as v;

/*--------------------------------------
 breadcrumb
---------------------------------------*/
.breadcrumb {
  width: 100%;
  padding: 2rem 0;
  @include m.sp {
    padding: 1rem 0;
  }
  &_item {
    max-width: 1280px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  li {
    span {
      @include m.fz(14);
      @include m.sp {
        @include m.fz(13);
        line-height: 1.5em;
      }
      @include m.sp_s {
        @include m.fz(11);
      }
    }
    &:not(:last-of-type)::after {
      content: "";
      margin: 0 1.3rem 0 1rem;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-right: 1px solid v.$main;
      border-bottom: 1px solid v.$main;
      transform: rotate(-45deg);
      @include m.sp_s {
        margin: 0 0.5rem;
      }
    }
    a {
      position: relative;
      &:hover {
        color: v.$green;
      }
    }
  }
}
