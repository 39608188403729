@use "global/mixin" as m;
@use "global/variable" as v;
/*------------------------------------
 ▼お問い合わせ​​ //contact
  -------------------------------------*/
.contact {
  text-align: center;
  h2 {
    @include m.sp {
      margin-bottom: 4rem;
    }
  }
  .l-section {
    &_inner {
      @include m.tab_s {
        padding: 5rem 0;
      }
    }
    &_fx {
      max-width: 744px;
      margin: 0 auto;
      &__lt {
        background: v.$white;
        border: 2px solid v.$main;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include m.pc {
          width: calc(460 / 744 * 100%);
        }
        @include m.sp {
          width: 50%;
          margin: 0 auto 2rem auto;
        }
        @include m.sp_l {
          width: 80%;
        }
        .tel_link {
          font-weight: 900;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            width: 26px;
            height: 34px;
            background: url(../../assets/img/common/tel_icon.svg) left / contain no-repeat;
            vertical-align: middle;
            margin: 0rem 1rem 0 0;
            @include m.sp {
              width: 16px;
              height: 24px;
            }
          }
        }
        span {
          display: block;
        }
      }
      &__rt {
        @include m.pc {
          width: calc(254 / 744 * 100%);
        }
        @include m.sp {
          width: 100%;
          margin: 0 auto 2rem auto;
        }
        @include m.sp_m {
          width: 80%;
        }
        .c-btn {
          @include m.sp {
            width: 80%;
            margin: 0 auto 2rem auto;
          }
          @include m.sp_m {
            width: 100%;
          }
        }
      }
    }
  }
}
