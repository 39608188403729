@use "global/mixin" as m;
@use "global/variable" as v;
/*--------------------------------------
  header
---------------------------------------*/
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  &.header_fixed {
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease-in-out;
    .l-header_contents {
      @include m.pcOver1201 {
        margin: 2rem auto;
      }
    }
  }

  &_contents {
    width: 97%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    @include m.pc1200 {
      width: 100%;
      height: 50px;
      margin: 0;
    }
  }
}
.h_logo {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  @include m.pc1200 {
    left: 1rem;
  }
  img {
    @include m.tab_s {
      width: 85%;
    }
  }
}
.logo_hidden {
  opacity: 0;
}
.nav_contents {
  position: relative;
  @include m.pc1200 {
    display: none;
    width: 100%;
    height: 100vh;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background: v.$white;
    overscroll-behavior-y: contain;
    overflow-y: auto;
  }
  .nav_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.pc1200 {
      width: 80%;
      margin: 10rem auto;
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      @include m.fz(14);
      font-weight: bold;
      line-height: 1.4;
      font-feature-settings: "palt";
      margin-right: 1.5vw;
      @include m.pc1200 {
        width: 100%;
        text-align: left;
        @include m.fz(20);
        margin: 0 0 2rem 0;
      }
      @include m.sp {
        @include m.fz(16);
        margin-bottom: 1rem;
      }
    }
    a {
      display: block;
      position: relative;
      &:hover {
        color: v.$green;
        opacity: 1;
      }
    }
    &_sub {
      &__title {
        position: relative;
        @include m.pcOver1201 {
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 6px 0 6px;
            border-color: v.$main transparent transparent transparent;
            position: absolute;
            bottom: -50%;
            left: 50%;
            transform: translate(-50%, 50%);
          }
        }
        @include m.pc1200 {
          text-align: left;
          padding: 1rem 0;
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -0.5rem;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-left: 2px solid v.$main;
            border-bottom: 2px solid v.$main;
            transform: rotate(-45deg);
            transition: all 0.3s;
          }
        }
      }
      &__inner {
        display: none;
        @include m.pc1200 {
          padding: 2rem 0 0 2rem;
        }
      }
      ul {
        @include m.pcOver1201 {
          position: absolute;
          padding: 4rem;
          margin: 5rem 0 0 -4rem;
          background: v.$green;
          display: flex;
          justify-content: space-between;

          &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 30px 15px;
            border-color: transparent transparent v.$green transparent;
            position: absolute;
            top: 0;
            left: 10.5rem;
            transform: translate(-50%, -50%);
          }
        }

        li {
          &:nth-last-of-type(1) {
            a {
              margin-right: 0;
            }
          }
          @include m.pc1200 {
            text-align: left;
            @include m.fz(18);
            margin: 0 0 2rem 0;
          }
          @include m.sp {
            @include m.fz(15);
          }
          a {
            @include m.pcOver1201 {
              text-align: center;
              color: v.$white;
            }
            @include m.pc1200 {
              position: relative;
              &::before {
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: v.$yellow_green;
                vertical-align: middle;
                margin: 0 0.5rem 0 0;
              }
            }

            img {
              max-width: 160px;
              margin-bottom: 0.5rem;
              @include m.pc1200 {
                display: none;
              }
            }
            span {
              display: block;
              @include m.fz(10);
              font-weight: 400;
              margin-top: 1rem;
              @include m.pc1200 {
                display: none;
              }
            }
            &:hover {
              @include m.pcOver1201 {
                cursor: pointer;
                color: v.$white;
                opacity: 0.8;
              }
            }
          }
        }
      }
      &:hover {
        @include m.pcOver1201 {
          cursor: pointer;
          color: v.$green;
        }
      }
    }
    @include m.pc1200 {
      .open {
        .nav_item_sub__title {
          &::after {
            transform: rotate(135deg);
            transition: all 0.3s;
          }
        }
      }
    }
    @include m.pcOver1201 {
      li {
        &:nth-of-type(4) {
          .nav_item_sub__inner ul {
            margin: 5rem 0 0 -4.4rem;
          }
        }
        &:nth-of-type(5) {
          .nav_item_sub {
            &__inner {
              ul {
                right: 0;
                &::before {
                  left: 56rem;
                }
              }
            }
          }
        }
      }
    }
    .h_btn {
      display: block;
      width: 210px;
      height: 40px;
      text-align: center;
      border: 2px solid v.$main;
      display: flex;
      align-items: center;
      justify-content: center;
      @include m.pc1200 {
        width: 300px;
        height: 60px;
        margin-top: 2rem;
      }
      @include m.sp {
        width: 80%;
        height: 50px;
        @include m.fz(15);
        margin: 2rem auto 1rem auto;
      }
      &:hover {
        background: v.$main;
        color: v.$white;
      }
      &._contact {
        width: 130px;
        color: v.$red;
        border: 2px solid v.$red;
        margin-right: 0;
        @include m.pc1200 {
          width: 300px;
          height: 60px;
        }
        @include m.sp {
          width: 80%;
          margin: 0 auto;
          @include m.fz(15);
        }
        &:hover {
          background: v.$red;
          color: v.$white;
        }
      }
    }
  }
}
.drawer_nav,
.menu_trigger {
  display: none;
}
@include m.pc1200 {
  .drawer_nav {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(0, -50%);
    z-index: 99;
    box-sizing: border-box;
  }
  .menu_trigger {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 25px;
    vertical-align: middle;
    cursor: pointer;
    @include m.sp {
      height: 20px;
    }
    span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 2em;
      height: 3px;
      background: v.$main;
      transition: all 0.5s;
      @include m.sp {
        width: 1.7em;
        height: 2px;
      }
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 45%;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
    &.active {
      span {
        background: v.$main;
        &:nth-of-type(1) {
          transform: translateY(11px) rotate(-45deg);
          @include m.sp {
            transform: translateY(9px) rotate(-45deg);
          }
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-11px) rotate(45deg);
          @include m.sp {
            transform: translateY(-9px) rotate(45deg);
          }
        }
      }
    }
  }
}
