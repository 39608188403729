@charset "UTF-8";

/* transition */
$anime: 0.3s ease;

/* color */
$main: #003c50;
$white: #fff;
$black: #1e1e1e;
$yellow_green: #64d246;
$green: #32ad4a;
$red: #dc3c50;
$yellow: #e6d23c;
$gray: #e5ebed;
$orange: #f19900;
$pink: #ed87bf;
$blue: #2982b8;

/* font-family */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Maru+Gothic:wght@400;500;700&display=swap");
$yugo: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$zen_maru: "Zen Maru Gothic", serif;
$noto_sans: "Noto Sans JP", sans-serif;
