@use "global/mixin" as m;
@use "global/variable" as v;

.u-color {
  &_text {
    &--main {
      color: v.$main;
    }
    &--white {
      color: v.$white;
    }
    &--yellow_green {
      color: v.$yellow_green;
    }
  }

  &_bg {
    &--black {
      background-color: v.$black;
    }
    &--gray {
      background-color: v.$gray;
    }

    &--green {
      background-color: v.$green;
    }
  }
}
