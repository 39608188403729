@use "global/mixin" as m;
@use "global/variable" as v;

.l-section {
  position: relative;
  &_inner {
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
    padding: 10rem 0;
    @include m.tab_s {
      padding: 5rem 0;
    }
    @include m.sp_s {
      padding: 3rem 0;
    }
  }
  &.__group {
    .l-section_inner {
      padding: 2rem 0 10rem 0;
      @include m.tab_s {
        padding: 0 0 3rem 0;
      }
    }
  }
  &_item {
    max-width: 1000px;
    margin: 0 auto;
  }
  &_fx {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__img {
      width: calc(550 / 1000 * 100%);
      @include m.tab_s {
        margin-bottom: 1em;
      }
    }
    &__col {
      width: 40%;
      @include m.pc_s {
        width: 45%;
      }
      @include m.tab_s {
        width: 100%;
        margin-bottom: 2rem;
      }
      &--pages {
        width: 55%;
        @include m.tab_s {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
    }
    &.--jc {
      justify-content: center;
    }
    &.--js {
      justify-content: flex-start;
    }
    &.--ac {
      align-items: center;
    }
    &.--ae {
      align-items: flex-end;
    }
    &.--2col {
      .l-section_fx {
        &__img {
          width: 100%;
          position: relative;
          z-index: -1;
          @include m.tabOver {
            flex: 1;
            margin-right: calc(50% - 50vw);
            margin-left: 5%;
          }
          &._lt {
            @include m.tabOver {
              margin-left: calc(50% - 50vw);
              margin-right: 5%;
            }
          }
          &.__pages {
            @include m.tabOver {
              width: 75%;
              margin-top: -10rem;
            }
          }
        }
      }
      .col_lt {
        @include m.pc {
          width: 45%;
        }
        @include m.tab_s {
          width: 100%;
        }
      }
      .img_rt {
        @include m.pc {
          width: 44%;
        }
        @include m.tab_s {
          width: 65%;
          margin: 0 auto;
        }
      }
      &.__reverse {
        flex-flow: column;
        @include m.tabOver {
          flex-flow: row-reverse;
          align-items: center;
        }
        .l-section_fx {
          &__col {
            .c-title {
              background: v.$white;
              position: relative;
              z-index: 2;
              @include m.tabOver {
                padding: 4rem 3rem;
              }
              @include m.tab_s {
                margin-bottom: 4rem;
              }
              &._lt {
                @include m.tabOver {
                  margin-left: -35%;
                }
              }
            }
          }
          .c-btn {
            @include m.sp {
              margin: 2rem auto 0 auto;
            }
          }
        }
        .c-title {
          background: #fff;
          @include m.tabOver {
            margin-left: -25%;
          }
        }
      }
    }
    &.--order {
      .l-section_fx {
        @include m.tab_s {
          flex-direction: column;
        }
        &__col {
          @include m.tab_s {
            display: contents;
            order: 2;
          }
          .c-title {
            @include m.tab_s {
              order: 1;
              margin-bottom: 4rem;
            }
          }
          &_inner {
            @include m.tab_s {
              order: 2;
              margin-top: 2rem;
            }
          }
        }
        &__img {
          @include m.tab_s {
            order: 1;
          }
        }
      }
    }
    &.--3col {
      @include m.tabOver {
        margin: 0 -1.5rem;
        justify-content: flex-start;
      }
      .l-section_fx {
        &__col {
          @include m.b_shadow;
          @include m.tabOver {
            width: calc((100% / 3) - 3rem);
            margin: 1.5rem;
          }
          @include m.tab_s {
            width: 85%;
            margin: 0 auto 3rem auto;
          }
          &:hover {
            .l-section_fx__col__img {
              img {
                transform: scale(1.2, 1.2);
                transition: 2s all;
              }
            }
          }
          &__img {
            overflow: hidden;
            @include m.tab_s {
              width: 100%;
            }
            img {
              transition: 2s all;
            }
          }
          &__btm {
            padding: 3rem 2rem;
            @include m.tab_s {
              padding: 2rem;
            }
          }
        }
      }
    }
  }
  &_head {
    margin-bottom: 120px;
    @include m.tab_s {
      margin-bottom: 3em;
    }
  }
}
