@use "global/mixin" as m;
@use "global/variable" as v;

.u-mb140 {
  margin-bottom: 14rem;
  @include m.sp {
    margin-bottom: 5rem;
  }
}
.u-mb100 {
  margin-bottom: 10rem;
  @include m.sp {
    margin-bottom: 4.5rem;
  }
}
.u-mb80 {
  margin-bottom: 8rem;
  @include m.sp {
    margin-bottom: 4rem;
  }
}
.u-mb70 {
  margin-bottom: 7rem;
  @include m.sp {
    margin-bottom: 3.5rem;
  }
}
.u-mb60 {
  margin-bottom: 6rem;
  @include m.sp {
    margin-bottom: 3.3rem;
  }
}
.u-mb50 {
  margin-bottom: 5rem;
  @include m.sp {
    margin-bottom: 3rem;
  }
}
.u-mb40 {
  margin-bottom: 4rem;
  @include m.sp {
    margin-bottom: 3rem;
  }
}
.u-mb30 {
  margin-bottom: 3rem;
  @include m.sp {
    margin-bottom: 1rem;
  }
}
.u-mb20 {
  margin-bottom: 2rem;
  @include m.sp {
    margin-bottom: 0.8rem;
  }
}
.u-mb10 {
  margin-bottom: 1rem;
  @include m.sp {
    margin-bottom: 0.5rem;
  }
}
.u-m_auto {
  margin: 0 auto;
}
