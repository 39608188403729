@use "global/mixin" as m;
@use "global/variable" as v;
@use "global/keyframe" as k;

.c-hero {
  &_contents {
    position: relative;
  }
  &_item {
    position: relative;
    animation: fade 2s ease-in-out forwards;
    &._index {
      .swiper-wrapper {
        overflow: hidden;
      }
      .swiper-slide-active img,
      .swiper-slide-duplicate-active img,
      .swiper-slide-prev img {
        animation: zoomout 7s linear 0s normal both;
        transform-origin: center center;
      }
      .swiper-slide img {
        min-height: calc(1150 / 1920 * 100vw);
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }
    &__text {
      position: absolute;
      top: 14%;
      left: 5%;
      z-index: 2;
      animation: fade_lt 2s ease-in-out forwards;
      @include m.pc1500 {
        top: 13%;
        width: 55%;
      }
      @include m.sp {
        width: 95%;
        top: 12%;
        left: 3%;
      }
    }
    &._pages {
      padding-top: 80px;
      @include m.pc1200 {
        padding-top: 50px;
      }
      .c-hero_item_img {
      }
      .c-title--pages {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        animation: fade_lt 2s ease-in-out forwards;
        @include m.tab {
          top: 55%;
          left: 5%;
        }
        @include m.sp_l {
          left: 2%;
          top: 65%;
        }
        span {
          display: inline-block;
          padding: 0 2rem;
          @include m.sp {
            padding: 0 1rem;
          }
        }
        .en {
          margin-bottom: 1rem;
          @include m.sp {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    &._none {
      @include m.sp {
        padding-top: 4em;
      }
    }
  }
}
