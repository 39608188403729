@use "global/mixin" as m;
@use "global/variable" as v;

.p-index {
  /*------------------------------------
   お知らせ​​ //News
  -------------------------------------*/
  .news {
    width: 100%;
    color: v.$white;

    @include m.tabOver {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 5%);
    }
    @include m.tab_s {
      margin-top: -20%;
    }
    @include m.sp {
      margin-top: -40%;
    }
    h2 {
      @include m.tab_s {
        margin-bottom: 0.5rem;
      }
    }
    .l-section_inner {
      padding: 4.5rem 5rem;
      background: rgba(1, 40, 51, 0.5);
      @include m.pc_s {
        padding: 2rem;
      }
      @include m.tab_s {
        background: rgba(1, 40, 51, 0.8);
      }
      h2 {
        span {
          display: inline-block;
          margin-right: 2rem;
          @include m.sp {
            margin-right: 1rem;
          }
        }
      }
      p {
        &:hover {
          text-decoration: underline;
        }
      }
      .c-post {
        &_item {
          margin-left: 4.5rem;
          @include m.pc_s {
            margin-left: 0;
          }
        }
      }
      .c-btn_item {
        text-align: right;
        margin-right: 3rem;
      }
    }
  }
  /*------------------------------------
  ▼会社案内・会社概要​​ //aboutus
  -------------------------------------*/
  .aboutus {
    .l-section {
      &_item {
        h3 {
          @include m.sp {
            width: 65%;
          }
        }
        &:nth-of-type(1) {
          .l-section_fx__col {
            @include m.sp {
              margin-bottom: 2rem;
            }
          }
        }
      }
      &_fx {
        &__item {
          display: flex;
          flex-direction: column;
          @include m.pc {
            width: 45%;
          }

          @include m.sp {
            margin-bottom: 5rem;
          }
          &___icon {
            display: inline-block;
            width: 6.8rem;
            margin-right: 3.5rem;
          }
          h4 {
            padding-left: 10rem;
            position: relative;
            @include m.tab_s {
              padding-left: 5rem;
              line-height: 1.5;
            }
            &::before {
              content: "";
              width: 100%;
              height: auto;
              padding-top: 10%;
              display: inline-block;
              background: url(../../assets/img/index/aboutus_icon01.svg) left / contain no-repeat;
              position: absolute;
              top: 15%;
              left: 0;
              @include m.tab_s {
                padding-top: 7%;
                top: 25%;
              }
            }
            span {
              display: block;
              @include m.fz(14);
              font-weight: 900;
            }
          }
          &:nth-of-type(2) {
            h4 {
              &::before {
                background: url(../../assets/img/index/aboutus_icon02.svg) left / contain no-repeat;
              }
            }
          }
          .c-btn_item {
            margin-top: auto;
          }
        }
      }
    }
    .swiper02 {
      @include m.pc {
        margin: 8rem 0 0 0;
      }
      .swiper-wrapper {
        transition-timing-function: linear;
      }
      .swiper-slide {
        width: 27%;
      }
    }
  }
  /*------------------------------------
  ▼金蜜花火​​ //kinmitsu
  -------------------------------------*/
  .kinmitsu {
    color: v.$white;
    h2 {
      @include m.pc {
        display: flex;
        align-items: baseline;
      }
      @include m.sp {
        width: 50%;
        margin-bottom: 4rem;
      }
      img {
        @include m.pc {
          margin-right: 3rem;
        }
        @include m.sp {
          margin-bottom: 1rem;
        }
      }
    }
    .l-section {
      &_fx {
        align-items: center;
        @include m.tab_s {
          flex-direction: column-reverse;
        }
        &__lt {
          @include m.pc {
            width: 50%;
          }
          @include m.tab_s {
            width: 100%;
          }
        }
        &__rt {
          @include m.pc {
            width: 38%;
          }
          @include m.tab {
            width: 50%;
            margin: 0 auto 1rem auto;
          }
          @include m.sp {
            width: 70%;
            margin: 0 auto 2rem auto;
          }
        }
      }
    }
    .c-btn {
      position: relative;
      @include m.tab_s {
        margin: 0 auto;
      }
      &::after {
        display: none;
      }
      &:hover {
        &::after {
          display: none;
        }
      }
    }
  }
  /*------------------------------------
  ▼採用情報​​ //recruit
  -------------------------------------*/
  .recruit {
    position: relative;
    @include m.pc1400 {
      margin-bottom: 10rem;
    }
    h3 {
      @include m.sp {
        width: 35%;
        margin: 0 auto 1rem auto;
      }
    }
    .l-section {
      &_fx {
        &__col {
          width: 35%;
          @include m.tab_s {
            width: 100%;
          }
          .c-box {
            @include m.pc {
              width: 480px;
              margin-left: -5rem;
              position: relative;
            }
            @include m.tab_s {
              margin-left: 0;
            }
          }
        }
        &__img {
          span {
            display: block;
            &:nth-of-type(2) {
              width: calc(410 / 858 * 100%);
              margin: 4rem 3rem 0 auto;
              @include m.sp {
                width: 100%;
                margin: 2rem auto 0 auto;
              }
            }
          }
        }
      }
    }
  }
  /*------------------------------------
 ▼オンラインストア​​​ //store
  -------------------------------------*/
  .store {
    margin-top: -20rem;
    padding: 0 0 7rem 0;
    @include m.tab_s {
      margin-top: -5rem;
    }
    @include m.sp {
      padding: 0 0 3rem 0;
    }
  }
}
