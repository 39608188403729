@use "global/mixin" as m;
@use "global/variable" as v;

.c-modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  &__overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    max-width: 800px;
    width: 90%;
    max-height: calc(var(--vh, 1vh) * 100 - 3em);
    padding: 3em 2em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: v.$white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: v.$gray;
    }
  }
  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1em;
    right: 1em;
    color: #000;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      width: 20px;
      height: 2px;
      background-color: currentColor;
      transform: rotate(-45deg);
    }
    &::after {
      content: "";
      position: absolute;
      top: 10px;
      width: 20px;
      height: 2px;
      background-color: currentColor;
      transform: rotate(45deg);
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
