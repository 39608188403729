@use "global/mixin" as m;
@use "global/variable" as v;

html {
  font-size: 10px;
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  @include m.fz(14);
  font-family: v.$noto_sans;
  font-weight: 500;
  color: v.$main;
  line-height: 2;
  letter-spacing: 0.1em;
  font-feature-settings: "palt";
  background: #ffffff;
  @include m.sp_s {
    @include m.fz(13);
  }
}
#container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
//--- anchor ---//
a {
  text-decoration: none;
  word-break: break-all;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
    -moz-opacity: 0.8;
  }
}
.tel_a:hover {
  cursor: text;
  opacity: 1;
  background: none;
  @include m.tab_s {
    cursor: pointer;
  }
}
//--- Layout ---//
.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}
.pc_sOver {
  display: none;
  @include m.pc_sOver {
    display: block;
  }
}
// min901
.tabOver {
  display: none;
  @include m.tabOver {
    display: block;
  }
}
.tab_s {
  display: none;
  @include m.tab_s {
    display: block;
  }
}
.sp {
  display: none;
  @include m.sp {
    display: block;
  }
}
.sp_mOver {
  display: none;
  @include m.sp_mOver {
    display: block;
  }
}
.sp_s {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: none;
  }
  @include m.sp_s {
    display: block;
  }
}
.inline {
  display: block;
  @include m.pc_s {
    display: inline;
  }
}
.in_block {
  display: inline-block;
}
.block {
  display: block;
}

//--- iframe_area ---//
.iframe_area {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
