@use "global/mixin" as m;
@use "global/variable" as v;

.c-box {
  padding: 2rem;
  background: v.$white;
  @include m.tab_s {
    padding: 1em 0.5em;
  }
  &_pages {
    width: 125%;
    padding: 2rem;
    margin-left: -2rem;
    background: v.$white;
    position: relative;
    z-index: 1;
    @include m.pc1200 {
      width: 100%;
    }
    @include m.tab_s {
      padding: 0;
      margin-left: 0;
    }
    &.__large {
      @include m.pc_sOver {
        width: 155%;
      }
      @include m.pc1200 {
        width: 100%;
      }
    }
  }
}
.c-label {
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 5px;
  background: v.$white;
  @include m.b_frame;
  @include m.sp {
    margin-bottom: 0.2em;
  }
}
.c-b_shadow {
  @include m.b_shadow;
}
.c-b_radius {
  border-radius: 0.7em;
}
.c-b_radius_lt {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  @include m.tab_s {
    border-radius: 0.5em;
  }
}
.c-b_radius_rt {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  @include m.tab_s {
    border-radius: 0.5em;
  }
}
.c-b_radius_lr {
  border-radius: 1em;
  @include m.tab_s {
    border-radius: 0.5em;
  }
}
