@use "global/mixin" as m;
@use "global/variable" as v;

/*------------------------------------
   ▼山瀬青果の人たち
  -------------------------------------*/
.p-akitapeople {
  .c-hero_item_img {
    img {
      @include m.sp_l {
        object-position: 20%;
      }
    }
  }
  .l-section {
    &.__member {
      .c-post_item {
        @include m.sp {
          flex-direction: row;
        }
        &__rt_img {
          &::after {
            @include m.sp {
              display: none;
            }
          }
          img {
            object-position: top;
          }
        }
        .text {
          line-height: 1.7;
        }
      }
    }
  }
  &.__member {
    .c-hero_item_img {
      img {
        @include m.sp_l {
          object-position: 50%;
        }
      }
    }
  }
}
