@use "global/mixin" as m;
@use "global/variable" as v;

.c-btn {
  display: block;
  max-width: 100%;
  width: 280px;
  height: 50px;
  text-align: center;
  @include m.fz(14);
  font-weight: bold;
  color: v.$white;
  background: v.$yellow_green;
  border: 1px solid v.$yellow_green;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // @include m.sp {
  //   width: 90%;
  // }
  &::after {
    content: "";
    display: inline-block;
    background: url(../../assets/img/common/link_icon_wh.svg) right/contain no-repeat;
    width: 100%;
    height: auto;
    padding-top: 5%;
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translate(0, -50%);
    margin-top: 0.1rem;
  }
  &:hover {
    color: v.$yellow_green;
    background: v.$white;
    &::after {
      background: url(../../assets/img/common/link_icon_y_green.svg) right/contain no-repeat;
    }
  }
  @include m.sp {
    @include m.fz(15);
  }
  &_item {
    @include m.sp {
      margin: 0 2rem 0 auto;
      text-align: right;
    }
  }

  &.-white {
    color: v.$black;
    background: v.$white;
    border: 1px solid v.$white;
    // &::after {
    //   background: url(../../assets/img/common/link_icon.svg) right/contain no-repeat;
    // }
    &:hover {
      color: v.$white;
      background: v.$black;
      // &::after {
      //   background: url(../../assets/img/common/link_icon_wh.svg) right/contain no-repeat;
      // }
    }
  }
  &.-main_arrow {
    color: v.$white;
    background: v.$main;
    border: 1px solid v.$main;
    &::after {
      right: 8%;
      width: 8px;
      height: 8px;
      border-right: 2px solid v.$white;
      border-bottom: 2px solid v.$white;
      transform: rotate(-45deg);
      background: none;
      margin-top: -0.5rem;
      padding: 0;
    }
    &:hover {
      color: v.$main;
      background: v.$white;
      &::after {
        border-right: 2px solid v.$main;
        border-bottom: 2px solid v.$main;
      }
    }
  }
  &._large {
    width: 250px;
    &__green {
      width: 250px;
      border: 2px solid v.$green;
      background: v.$green;
      &:hover {
        color: v.$green;
        background: v.$white;
      }
    }
  }
  &--link {
    @include m.fz(14);
    font-weight: bold;
    line-height: 2;
    color: v.$yellow_green;
    position: relative;
    transform: all 0.3s;
    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent v.$yellow_green;
      position: absolute;
      top: 50%;
      right: -30%;
      transform: translate(0, -50%);
      margin-top: 0.2rem;
    }
    span {
      border-bottom: 1px solid;
    }
    &:hover {
      color: v.$green;
      &::after {
        border-color: transparent transparent transparent v.$green;
      }
    }
    &.-white {
      color: v.$white;
      color: v.$white;
      &::after {
        &::after {
          border-color: transparent transparent transparent v.$white;
          border-color: transparent transparent transparent v.$white;
        }
      }
    }
    &_bnr {
      display: block;
      max-width: 750px;
      width: 85%;
      margin: 8rem auto 7rem auto;
      position: relative;
      @include m.pc_s {
        margin: 5rem auto 6rem auto;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 1em;
        background-color: rgba(0, 60, 80, 0.6);
      }
      span {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include m.fz(36);
        @include m.tab_s {
          @include m.fz(25);
        }
        @include m.sp {
          @include m.fz(20);
        }
        @include m.sp_s {
          @include m.fz(18);
        }
      }
      &:hover {
        &:before {
          background-color: rgba(0, 60, 80, 0.6);
        }
      }
      &.__green {
        width: 80%;
        @include m.sp {
          width: 100%;
        }
        &::before {
          background-color: rgba(50, 173, 74, 0.7);
        }
        span {
          line-height: 1.5;
          @include m.sp {
            @include m.fz(12);
          }
        }
        .c-btn {
          margin-top: 1.5rem;
          @include m.sp {
            width: 50%;
            height: 35px;
            margin-top: 0.5rem;
            @include m.fz(13);
          }
        }
      }
    }
  }
}
