@use "global/mixin" as m;
@use "global/variable" as v;

.p-policy {
  .l-section {
    &_inner {
      max-width: 1000px;
    }
    &_deteil {
      margin-bottom: 4rem;
      a {
        text-decoration: underline;
      }
      ol {
        list-style-type: decimal;
        list-style-position: inside;
        li {
          text-indent: -2rem;
          padding-left: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
