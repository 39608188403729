@use "global/mixin" as m;
@use "global/variable" as v;

.c-ac_list {
  dt,
  dd {
    @include m.fz(16);
    padding: 2rem 2rem 2rem 3rem;
    @include m.sp {
      @include m.fz(15);
      padding: 1rem 3rem 1rem 1rem;
    }
    @include m.sp_s {
      @include m.fz(14);
    }
  }
  dt {
    border-bottom: 1px solid v.$main;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 5%;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      border-left: 2px solid v.$main;
      border-bottom: 2px solid v.$main;
      transform: rotate(-45deg);
      transition: 0.3s;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8s;
    }
    &.open {
      &::after {
        margin-top: 0;
        transform: rotate(135deg);
      }
    }
  }
  dd {
    display: none;
    background: v.$gray;
    @include m.sp {
      padding: 1rem;
    }
  }
}
